import { plan, planInvoice, planCard } from '../../utils/api';
//
export const getPlan = (id) => {
    return dispatch => {
        dispatch({ type: 'FETCH_PLAN_PENDING' }); //to load
        plan(id,
            (res) => {
                dispatch({ type: 'FETCH_PLAN_SUCCESS', plan: res });
            },
            (err) => {
                dispatch({ type: 'FETCH_PLAN_ERROR' });
            });
    }
}
export const planInvoicePay = (id, fields, success, error) => {
    return dispatch => {
        dispatch({ type: 'FETCH_PLAN_PAY_PENDING' }); //to load
        planInvoice(id, fields,
            (res) => {
                dispatch({ type: 'FETCH_PLAN_PAY_SUCCESS'});
                if (success) success(res);
            },
            (err) => {
                dispatch({ type: 'FETCH_PLAN_PAY_ERROR' });
                if (error) error(err);
            });
    }
}
export const planCardPay = (id, fields, success, error) => {
    return dispatch => {
        dispatch({ type: 'FETCH_PLAN_PAY_PENDING' }); //to load
        planCard(id, fields,
            (res) => {
                dispatch({ type: 'FETCH_PLAN_PAY_SUCCESS'});
                if (success) success(res);
            },
            (err) => {
                dispatch({ type: 'FETCH_PLAN_PAY_ERROR' });
                if (error) error(err);
            });
    }
}