import React, { useEffect, useState } from 'react';
import { Image, Form } from 'react-bootstrap';
//REDUX
import { connect } from 'react-redux';
import { refreshToken, authUser, getUser } from '../../store/actions/authActions';
//UTILS
import { getImageURL } from '../../utils/utils';
import { Link, useHistory } from 'react-router-dom';
//
function Login({ auth, refreshToken, authUser, getUser }) {
    const history = useHistory();
    console.log(auth.pending);
    const [fields, setFields] = useState({
        email: '',
        password: '',
    });
    function changeFormFields(e) {
        let newFields = { ...fields };
        newFields[e.target.name] = e.target.value;
        setFields(newFields);
        e.preventDefault();
    }
    function submitForm(e) {
        e.preventDefault();
        authUser(
            fields,
            () => {
                getUser();
            },
            (err) => {
                console.log(err);
            }
        );
    }
    useEffect(() => {
        refreshToken();
        if (auth.token != null) {
            history.push("/home");
        }
    }, [auth.token]);
    //
    return (
        <>
            <section className="mbr-section extForm cid-sz7j2vwKcW cid-sz74JbitiK " style={{ backgroundImage: `url(${getImageURL('/images/account-bg.jpg')})` }}>
                <div className="container">
                    <div className="media-container-row">
                        <div className="col-md-6 col-lg-6 block-content">
                            <div className="form-block">
                                <div className="bg"></div>
                                <div className="form-wrap">
                                    <Form className="mbr-form form-with-styler">
                                        <div className="dragArea form-row justify-content-center">
                                            <div className="col-8 mb-4">
                                                <Image src={getImageURL('/images/logo/branco_roxo_wide.png')} alt="logo" className="img-fluid" />
                                            </div>
                                            <div className="col-md-12">
                                                <h4 className="mbr-fonts-style mb-4 mbr-fonts-style display-5 text-center">ACESSE SUA CONTA</h4>
                                            </div>
                                            <div className="col-md-12 form-group" data-for="email">
                                                <input type="text" name="email" placeholder="Email" onChange={changeFormFields} className="form-control input display-7" required="required" />
                                            </div>
                                            <div className="col-md-12 form-group" data-for="password">
                                                <input type="password" name="password" placeholder="Senha" onChange={changeFormFields} className="form-control input display-7" required="required" />
                                            </div>
                                            <div className="col-md-12 form-group mt-2 d-flex justify-content-center">
                                                <button onClick={submitForm} className="btn btn-primary btn-bgr display-4">ACESSE</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="text-block">
                                <h2 className="mb-4 mbr-fonts-style mbr-section-title display-2">Não possui uma conta?</h2>
                                <div className="mbr-section-btn mb-5">
                                    <Link className="btn btn-md btn-white-outline btn-bgr display-4" to="/cadastro">CADASTRE-SE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                auth.pending ?
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                    : <></>
            }
        </>
    );
}
const mapStateToProps = state => ({
    auth: state.auth
})
const mapDispatchToProps = (dispatch) => {
    return {
        refreshToken: (success, error) => dispatch(refreshToken(success, error)),
        getUser: (success, error) => dispatch(getUser(success, error)),
        authUser: (fields, success, error) => dispatch(authUser(fields, success, error))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
