import React from 'react';
import {Route} from 'react-router-dom';
import { initializeGA } from './ga';
//COMPONENTS
import AutoTop from '../components/autoTop';

export default function PublicRouter(props){
    const { component: Component, path } = props;
    initializeGA(path);
    return(
        <>
            <AutoTop />
            <Route path={path} component={Component} {...props} />
        </>
    )
}