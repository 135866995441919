export function getImageURL(imageURL) {
    //USE ESSA FUNÇÃO PARA PEGAR A URL RELATIVA CORRETA DAS IMAGENS
    return `${window.location.origin + process.env.PUBLIC_URL + imageURL}`;
}
export function getURLToString(i, f){
    let removed = window.location.pathname.split('/').splice(i, f-1);
    let urlString = removed.toString();
    urlString = urlString.replace(/,/g,'/');
    return urlString;
}
export function removeSeparator(string, separator=' '){
    let splitedString = string.split(separator);
    return splitedString.toString().replace(/,/g,' ');
}
export function checkEmptyFields(fields) {
    let newFields = fields;
    //
    for (var key in newFields) {
        if (!newFields[key]) {
            return true;
        }
    }
    //
    return false;
}
export function preventScript(text){
    return text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}
