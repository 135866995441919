import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//Redux
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
//BOOTSTRAP CSS
import 'bootstrap/dist/css/bootstrap.min.css';
//Components
import Preloader from './components/preloader';
import initialState from './store/initialState';
import root from './store/reducers/root';
//SITE CSS
import './index.css';
import './assets/css/all.min.css';
import './assets/css/animate.css';
import './assets/css/flaticon.css';
import './assets/css/jquery-ui.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/main.css';
import './assets/css/nice-select.css';
import './assets/css/owl.min.css';
//MOBIRISE CSS
import './assets/mobirise/assets/web/assets/mobirise-icons/mobirise-icons.css';
import './assets/mobirise/assets/tether/tether.min.css';
import './assets/mobirise/assets/bootstrap/css/bootstrap.min.css';
import './assets/mobirise/assets/bootstrap/css/bootstrap-grid.min.css';
import './assets/mobirise/assets/bootstrap/css/bootstrap-reboot.min.css';
import './assets/mobirise/assets/animatecss/animate.min.css';
import './assets/mobirise/assets/dropdown/css/style.css';
import './assets/mobirise/assets/socicon/css/styles.css';
import './assets/mobirise/assets/theme/css/style.css';
import './assets/mobirise/assets/recaptcha.css';
import './assets/mobirise/assets/mobirise/css/mbr-additional.css';
import './assets/mobirise/assets/mobirise/css/mbr-additional.css';
//
const middlewares = [thunk];
const store = createStore(root, initialState, applyMiddleware(...middlewares));
//
ReactDOM.render(
    <Provider store={store}>
        <Preloader />
        <App />
    </Provider>,
    document.getElementById('root')
);