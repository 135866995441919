import React, { useState, useEffect } from 'react';
import { Image, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
//REDUX
import { connect } from 'react-redux';
import { registerUser } from '../../store/actions/authActions';
//UTILS
import { getImageURL } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
// import ExternalLogin from '../../components/externalLogin';
//
function Cadastro({registerUser, auth}) {
    const history = useHistory();
    const [fields, setFields] = useState({
        name: '',
        telefone: '',
        data_nascimento: '',
        cpf: '',
        email: '',
        password: '',
    });
    //
    function changeFormFields(e, replace) {
        let newFields = { ...fields };
        newFields[e.target.name] = (replace ? e.target.value.replace(/[^\d]/g, '') : e.target.value);
        setFields(newFields);
        e.preventDefault();
    }
    function submitForm(e) {
        e.preventDefault();
        registerUser(fields,
            () => {
                history.push("/login");
            },
            (err) => {
                console.log(err.data);
            }
        );
    }
    useEffect(()=>{
        if(auth.token != null){
            history.push("/home");
        }
    },[auth]);
    //
    return (
        <>
            <section className="mbr-section extForm cid-sz7j2vwKcW cid-sz74JbitiK " style={{ backgroundImage: `url(${getImageURL('/images/account-bg.jpg')})` }}>
                <div className="container">
                    <div className="account-title text-center">
                        <Image src={getImageURL('/images/logo/branco_roxo_wide.png')} alt="logo" />
                    </div>
                    <div className="media-container-row">
                        <div className="col-6 block-content">
                            <div className="form-block">
                                <div className="bg"></div>
                                <div className="form-wrap">
                                    <Form className="mbr-form form-with-styler">
                                        <div className="dragArea form-row justify-content-center">
                                            <div className="col-md-12">
                                                <h4 className="mbr-fonts-style mb-4 mbr-fonts-style display-5 text-center">Criar uma Conta</h4>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <input name="name" type="text" placeholder="Seu Nome Completo" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <InputMask name="telefone" mask="(99)9 9999-9999" maskChar={null} placeholder="(71)99999-9999" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, true) }} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <input name="data_nascimento" type="date" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <InputMask name="cpf" defaultValue={fields['cpf'] || ''} mask="999.999.999-99" maskChar={null} placeholder="111.111.111-11" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, true) }} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <input name="email" type="tel" placeholder="Esse será seu login" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <input name="password" type="password" placeholder="Sua senha" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                                            </div>
                                            <div className="col-md-12 form-group mt-2 d-flex justify-content-center">
                                                <button onClick={submitForm} className="btn btn-primary btn-bgr display-4">ACESSE</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {
                auth.pending ?
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                    : <></>
            }
        </>
    );
}
const mapStateToProps = state => ({
    auth: state.auth
})
const mapDispatchToProps = (dispatch) => {
    return {
        registerUser: (fields, success, error) => dispatch(registerUser(fields, success, error))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);
