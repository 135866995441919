import $ from 'jquery';
import axios from 'axios';

const url = process.env.NODE_ENV === 'development'? 'http://127.0.0.1:8000/api' : 'https://api.voxov.com.br/public/api';

//API EXTERNAS
export const checkoutCep = (cep, success) => {
    //Documentation -> https://viacep.com.br/
    //
    $.ajax({
        url: 'https://viacep.com.br/ws/' + cep + '/json/',
        type: 'GET',
        success: success
    });
}
//API VOXOV
export const login = (fields, Success, Error) => {
    axios.post(url+`/auth/login`,
        {
            email: fields.email,
            password: fields.password,
        }
    ).then(res => {
        Success(res.data);
    }).catch(error => {
        Error(error);
    });
}
export const register = (fields, Success, Error) => {
    axios.post(url+`/user/register`,
        {
            name: fields.name,
            telefone: fields.telefone,
            data_nascimento: fields.data_nascimento,
            cpf: fields.cpf,
            email: fields.email,
            password: fields.password,
        }
    ).then(res => {
        Success(res.data);
    }).catch(error => {
        Error(error);
    });
}
export const updateToken = (currentToken, Success, Error) => {
    axios.defaults.headers.common = { 'Authorization': `bearer ${currentToken}` }
    axios.get(url+`/user`).then(res => {
        Success(res.data);
    }).catch(error => {
        Error(error);
    });
}
export const userData = (currentToken, Success, Error) => {
    axios.defaults.headers.common = { 'Authorization': `bearer ${currentToken}` }
    axios.get(url+`/auth/me`).then(res => {
        Success(res.data);
    }).catch(error => {
        Error(error);
    });
}
export const plan = (plan_id, Success, Error) => {
    axios.post(url+`/plan`,
        {
            id: plan_id,
        }
    ).then(res => {
        Success(res.data);
    }).catch(error => {
        Error(error);
    });
}
export const planInvoice = (plan_id, fields, Success, Error) => {
    axios.post(url+`/plan/invoice-pay`,
        {
            plan_id: plan_id,
            name: fields.name,
            telefone: fields.telefone,
            data_nascimento: fields.data_nascimento,
            cpf: fields.cpf,
            email: fields.email,
            password: fields.password,
            cep: fields.cep,
            endereco: fields.endereco,
            numero: fields.numero,
            complemento: fields.complemento,
            bairro: fields.bairro,
            cidade: fields.cidade,
            estado: fields.estado
        }
    ).then(res => {
        console.log(res);
        Success(res.data);
    }).catch(error => {
        console.log(error);
        Error(error);
    });
}
export const planCard = (plan_id, fields, Success, Error) => {
    axios.post(url+`/plan/card-pay`,
        {
            plan_id: plan_id,
            name: fields.name,
            telefone: fields.telefone,
            data_nascimento: fields.data_nascimento,
            cpf: fields.cpf,
            email: fields.email,
            password: fields.password,
            cep: fields.cep,
            endereco: fields.endereco,
            numero: fields.numero,
            complemento: fields.complemento,
            bairro: fields.bairro,
            cidade: fields.cidade,
            estado: fields.estado
        }
    ).then(res => {
        console.log(res)
        Success(res.data);
    }).catch(error => {
        console.log(error)
        Error(error);
    });
}