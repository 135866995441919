import React, { useEffect } from 'react';
import { useLocation, withRouter } from "react-router-dom";
//
function AutoTop() {
    const { pathname } = useLocation();
    //
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    //
    return (
        <React.Fragment />
    );
}
export default withRouter(AutoTop);