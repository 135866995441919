import React, { useState, useEffect } from 'react';
//IMAGES
import { Image } from 'react-bootstrap';
//UTILS
import { getImageURL } from '../../utils/utils';
//
export default function Preloader(props) {
    const [animate, setAnimate] = useState(false);
    //
    useEffect(() => {
        if (!animate) {
            const interval = setInterval(() => {
                setAnimate(true);
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [animate]);
    //
    return (
        <>
            {/* <!--============= Preloader Section Starts Here =============--> */}
            <div className={`preloader ${animate ? 'animated fadeOut' : ''}`} style={{ display: `${animate ? 'none' : 'block'}` }}>
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <Image src={getImageURL('/images/logo/voxov_logo_animada.svg')} alt="Imagem de Carregamento" />
                    </div>
                </div>
            </div>
            {/* <!--============= Preloader Section Ends Here =============--> */}
        </>
    );
}