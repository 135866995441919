import { login, updateToken, userData, register } from '../../utils/api';
//
export const refreshToken = (success, error) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_REFRESH_TOKEN_PENDING' }); //to load
        const token = localStorage.getItem('token');
        if (token !== null) {
            updateToken(token,
                (res) => {
                    dispatch({ type: 'FETCH_REFRESH_TOKEN_SUCCESS', token: res.token });
                    if (success) success(res);
                },
                (err) => {
                    dispatch({ type: 'FETCH_REFRESH_TOKEN_ERROR' });
                    if (error) error(err);
                });
        }else{
            dispatch({ type: 'FETCH_REFRESH_TOKEN_ERROR' });
            if (error) error();
        }
    }
}
export const authUser = (fields, success, error) => {
    return dispatch => {
        dispatch({ type: 'FETCH_AUTH_USER_PENDING' }); //to load
        login(fields,
            (res) => {
                dispatch({ type: 'FETCH_AUTH_USER_SUCCESS', token: res.access_token });
                if (success) success(res);
            },
            (err) => {
                dispatch({ type: 'FETCH_AUTH_USER_ERROR' });
                if (error) error(err);
            });
    }
}
export const registerUser = (fields, success, error) => {
    return dispatch => {
        dispatch({ type: 'FETCH_REGISTER_USER_PENDING' }); //to load
        register(fields,
            (res) => {
                dispatch({ type: 'FETCH_REGISTER_USER_SUCCESS' });
                if (success) success(res);
            },
            (err) => {
                dispatch({ type: 'FETCH_REGISTER_USER_ERROR' });
                console.log(err.response)
                if(err.response.status === 500){
                    alert('Não foi possivel efetuar seu cadastro. Os dados informados não podem ser utilizados para uma nova conta.');
                }else if(err.response.status === 400){
                    alert('Tivemos algum problema com seu cadastro. Verifique os dados informados e tente novamente.');
                }else{
                    alert('Tivemos algum problema com seu cadastro. Verifique sua conexão com a internet e tente novamente.');
                }
                //
                if (error) error(err);
            });
    }
}
export const getUser = (success, error) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_GET_USER_PENDING' }); //to load
        const { token } = getState().auth;
        if (token !== null) {
            userData(token,
                (res) => {
                    dispatch({ type: 'FETCH_GET_USER_SUCCESS', user: res.user });
                    if (success) success(res);
                },
                (err) => {
                    dispatch({ type: 'FETCH_GET_USER_ERROR' });
                    if (error) error(err);
                });
        } else {
            dispatch({ type: 'FETCH_GET_USER_ERROR' });
            if (error) error();
        }
    }
}