import React from 'react';
import Helmet from 'react-helmet';
//COMPONENTS
import Footer from '../components/footer';
import BackHome from '../components/backHome';
//
export default function Page(props) {
    const { container, component: Component, label } = props;
    return class extends React.Component {
        render() {
            return (
                <>
                    <Helmet>
                        <title>
                            {label}
                        </title>
                    </Helmet>
                    {
                        container ?
                            <>
                                <BackHome />
                                <Component {...this.props} />
                                <Footer />
                            </>
                            :
                            <Component {...this.props} />
                    }
                </>
            );
        }
    }
}
