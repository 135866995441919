import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
//UTILS
import PublicRouter from './utils/publicRouter';
import Page from './utils/page';
//PAGES
import Login from './pages/login';
import Cadastro from './pages/cadastro';
import Contratacao from './pages/contratacao';
//
export default function App() {
  //
  const globalTitle = "Central Do Cliente";
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <PublicRouter path={`/`} component={Page({ container: true, component: Login, label: globalTitle })} exact />
          <PublicRouter path={`/login`} component={Page({ container: true, component: Login, label: globalTitle })} exact />
          <PublicRouter path={`/contratacao/:plan_id`} component={Page({ container: true, component: Contratacao, label: globalTitle + " - Contratação" })} exact />
          <PublicRouter path={`/cadastro`} component={Page({ container: true, component: Cadastro, label: globalTitle + " - Cadastro"})} exact />
        </Switch>
      </BrowserRouter>
    </>
  );
}
