import React from 'react';
//
export default function BackHome() {
    //
    return (
        <>
            <section className="menu1 businessm4_menu1 menu cid-sz756eI19K" id="menu1-64">
                <nav className="navbar navbar-dropdown navbar-expand-lg">
                    <div className="navbar-brand">
                        <span className="navbar-caption-wrap">
                            <a className="navbar-caption text-white text-primary display-5" target="_blank" rel="noreferrer" href="https://voxov.com.br">Voltar</a>
                        </span>
                    </div>
                </nav>
            </section>
        </>
    );
}
