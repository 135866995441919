const thisInitialState = {
    token: null,
    user: null,
    error: false,
    pending: false
}
//
export function authReducer(state = thisInitialState, action) {
    switch (action.type) {
        //REFRESH
        case 'FETCH_REFRESH_TOKEN_PENDING':
            return {
                ...state,
                pending: true,
                error: false,
                token: null
            }
        case 'FETCH_REFRESH_TOKEN_SUCCESS':
            localStorage.setItem('token', action.token);
            return {
                ...state,
                pending: false,
                error: false,
                token: action.token
            }
        case 'FETCH_REFRESH_TOKEN_ERROR':
            localStorage.removeItem('token');
            return {
                ...state,
                pending: false,
                error: true,
                token: null
            }
        //AUTH
        case 'FETCH_AUTH_USER_PENDING':
            return {
                ...state,
                pending: true,
                error: false,
                token: null
            }
        case 'FETCH_AUTH_USER_SUCCESS':
            localStorage.setItem('token', action.token);
            return {
                ...state,
                pending: false,
                error: false,
                token: action.token
            }
        case 'FETCH_AUTH_USER_ERROR':
            return {
                ...state,
                pending: false,
                error: true,
                token: null
            }
        //GET USER
        case 'FETCH_GET_USER_PENDING':
            return {
                ...state,
                pending: true,
                error: false,
                user: null
            }
        case 'FETCH_GET_USER_SUCCESS':
            return {
                ...state,
                pending: false,
                error: false,
                user: action.user
            }
        case 'FETCH_GET_USER_ERROR':
            return {
                ...state,
                pending: false,
                error: true,
                user: null
            }
        //REGISTER USER
        case 'FETCH_REGISTER_USER_PENDING':
            return {
                ...state,
                pending: true,
                error: false,
                user: null,
                token: null
            }
        case 'FETCH_REGISTER_USER_SUCCESS':
            return {
                ...state,
                pending: false,
                error: false,
                user: null,
                token: null
            }
        case 'FETCH_REGISTER_USER_ERROR':
            return {
                ...state,
                pending: false,
                error: true,
                user: null,
                token: null
            }
        default:
            return state;
    }
}