import React, { useState, useEffect } from 'react';
import { Image, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
//REDUX
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { getPlan, planInvoicePay, planCardPay } from '../../store/actions/plansActions';
import { registerUser } from '../../store/actions/authActions';
//UTILS
import { getImageURL } from '../../utils/utils';
//
function Contratacao({ auth, plan, getPlan, planInvoicePay, planCardPay }) {
    const history = useHistory();
    let { plan_id } = useParams();
    //
    const [formStep, setFormStep] = useState(0);
    const [checkPayment, setCheckPayment] = useState(1);
    //
    const [fields, setFields] = useState({
        name: '',
        telefone: '',
        data_nascimento: '',
        cpf: '',
        email: '',
        password: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        checkbox: '1'
    });
    //
    useEffect(() => {
        if (plan.plan === null && plan.pending === false) {
            getPlan(plan_id);
        }
    }, [formStep]);
    //
    function changeFormFields(e, replace) {
        let newFields = { ...fields };
        newFields[e.target.name] = (replace ? e.target.value.replace(/[^\d]/g, '') : e.target.value);
        setFields(newFields);
        e.preventDefault();
    }
    function setCheckBox(e){
        let newFields = { ...fields };
        newFields['checkbox'] =  e.target.value;
        setFields(newFields);
        e.preventDefault();
    }
    function submitForm(e) {
        if (fields['checkbox'] === '1') {
            planInvoicePay(plan_id, fields,
                (res) => {
                    alert(res.message+' \n Iremos lhe redirecionar para o link de pagamento.');
                    window.location.href = res.url;
                },
                () => {
                    alert('Não foi possivel finalizarmos o processo. Verifique seus dados e tente novamente.');
                });
        } else if (fields['checkbox'] === '2') {
            planCardPay(plan_id, fields,
                (res) => {
                    alert(res.message+' \n Iremos lhe redirecionar para o link de pagamento.');
                    window.location.href = res.url;
                },
                () => {
                    alert('Não foi possivel finalizarmos o processo. Verifique seus dados e tente novamente.');
                });
        }
        e.preventDefault();
    }
    const planCard = () => {
        const planData = [
            '1GB de Internet \n Ligações Ilimitadas para fixo e móvel* \n 100 SMS \n Roaming Nacional',
            '10 GB de Internet \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas **  \n Roaming Nacional',
            '15 GB de Internet \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional',
            '25 GB de Internet \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional \n Roaming Internacional',
            '40 GB de Internet \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional \n Roaming Internacional',
            '30 GB de Internet \n Até 3 chips \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional ',
            '50 GB de Internet \n Até 4 chips \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional \n Roaming Internacional ',
            '50 GB de Internet \n Até 4 chips \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional \n ',
            '100 GB de Internet \n Até 8 chips \n internet infinita * \n Ligações e SMS Ilimitados \n Whatsapp e redes sociais ilimitadas ** \n Roaming Nacional \n Roaming Internacional ',
        ]
        if (plan.plan !== null) {
            return (
                <div className="table first-table col-md-3 col-lg-3 ml-5 align-left">
                    <div className="table-wrapper">
                        <div className="table-head-wrapper">
                            <span className="table-heading mbr-section-title mbr-fonts-style mbr-bold display-5">{plan.plan.name}</span>
                            <div className="table-purchase">
                                <span className="pricing-value mbr-fonts-style display-4"></span>
                                <h3 className="mbr-text table-pricing mbr-fonts-style mbr-lighter display-5">R$ {plan.plan.price}/mês</h3>
                            </div>
                        </div>
                        <ul className="list-group mbr-fonts-style mbr-lighter display-7">
                            {planData[plan_id - 1].split('\n').map((item, i) =>
                                <li key={i}>{item}</li>
                            )}
                        </ul>
                    </div>
                </div>
            )
        }
        return (<></>);
    }
    function swapFrontForm(e) {
        e.preventDefault();
        setFormStep(1);
    }
    function swapBackForm(e) {
        e.preventDefault();
        setFormStep(0);
    }
    //
    const withoutLoginForm = () => {
        let content = [
            (
                <Form className="mbr-form form-with-styler">
                    <div className="dragArea form-row justify-content-center">
                        <div className="col-md-12">
                            <h4 className="mbr-fonts-style mb-4 mbr-fonts-style display-5 text-center">Finalizar Compra 1 / 2</h4>
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['name']} name="name" type="text" placeholder="Seu Nome Completo" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <InputMask defaultValue={fields['telefone']} name="telefone" mask="(99)9 9999-9999" maskChar={null} placeholder="(71)99999-9999" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, true) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['data_nascimento']} name="data_nascimento" type="date" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <InputMask defaultValue={fields['cpf']} name="cpf" defaultValue={fields['cpf'] || ''} mask="999.999.999-99" maskChar={null} placeholder="111.111.111-11" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, true) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['email']} name="email" type="tel" placeholder="Esse será seu login" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input name="password" type="password" placeholder="Sua senha" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group mt-2 d-flex justify-content-center">
                            <button onClick={swapFrontForm} className="btn btn-primary btn-bgr display-4">AVANÇAR</button>
                        </div>
                    </div>
                </Form>
            ),
            (
                <Form className="mbr-form form-with-styler">
                    <div className="dragArea form-row justify-content-center">
                        <div className="col-md-12">
                            <h4 className="mbr-fonts-style mb-4 mbr-fonts-style display-5 text-center">Finalizar Compra 2 / 2</h4>
                        </div>
                        <div className="col-md-12 form-group">
                            <InputMask defaultValue={fields['cep']} name="cep" mask="99999-999" maskChar={null} placeholder="CEP" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, true) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['endereco']} name="endereco" type="text" placeholder="Rua. Av." className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['numero']} name="numero" type="text" placeholder="Numero" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['complemento']} name="complemento" type="text" placeholder="Complemento" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['bairro']} name="bairro" type="text" placeholder="Bairro" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['cidade']} name="cidade" type="text" placeholder="Cidade" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group">
                            <input defaultValue={fields['estado']} name="estado" type="text" placeholder="Estado" className="w-100 form-control input display-7" onChange={(e) => { changeFormFields(e, false) }} />
                        </div>
                        <div className="col-md-12 form-group mb-3 text-white">
                            <Form.Check className="text-white" inline label="Boleto" name="group1" type='radio' id={`inline-radio-1`} value={1} onChange={setCheckBox} defaultChecked />
                            <Form.Check className="text-white" inline label="Cartão" name="group1" type='radio' id={`inline-radio-2`} value={2} onChange={setCheckBox} />
                        </div>
                        <div className="col-md-12 form-group mt-2 d-flex justify-content-center">
                            <button onClick={swapBackForm} className="btn btn-primary btn-bgr display-4 mr-5">VOLTAR</button>
                            <button onClick={submitForm} className="btn btn-primary btn-bgr display-4">FINALIZAR</button>
                        </div>
                    </div>
                </Form>
            ),
        ]
        return (
            <>
                {content[formStep]}
            </>
        );
    }
    //
    return (
        <>
            <section className="mbr-section extForm cid-sz7j2vwKcW cid-sz74JbitiK " style={{ backgroundImage: `url(${getImageURL('/images/account-bg.jpg')})` }}>
                <div className="container">
                    <div className="media-container-row">
                        {planCard()}
                        <div className="col-md-6 col-lg-6 block-content">
                            <div className="form-block">
                                <div className="bg"></div>
                                <div className="form-wrap">
                                    {withoutLoginForm()}
                                </div>
                            </div>
                        </div>
                        {/* {switchForm ? <></> :
                            <div className="col-md-3 col-lg-3">
                                <div className="text-block">
                                    <h2 className="mb-4 mbr-fonts-style mbr-section-title display-2">Não possui uma conta?</h2>
                                    <h3 className="mb-4 mbr-fonts-style display-4">Na VOXOV pensamos em agilidade!</h3>
                                    <div className="mbr-section-btn mb-5">
                                        <button className="btn btn-md btn-white-outline btn-bgr display-4 rounded-pill" onClick={() => setSwitchForm(!switchForm)}>COMPRA RÁPIDA</button>
                                    </div>
                                </div>
                            </div>
                        } */}
                    </div>
                </div>
            </section>
            {
                auth.pending || plan.pending ?
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                    : <></>
            }
        </>
    );
}
const mapStateToProps = state => ({
    auth: state.auth,
    plan: state.plan
})
const mapDispatchToProps = (dispatch) => {
    return {
        getPlan: (id) => dispatch(getPlan(id)),
        registerUser: (fields, success, error) => dispatch(registerUser(fields, success, error)),
        planInvoicePay: (id, fields, success, error) => dispatch(planInvoicePay(id, fields, success, error)),
        planCardPay: (id, fields, success, error) => dispatch(planCardPay(id, fields, success, error))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contratacao);
