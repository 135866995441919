import ReactGA, { pageview } from 'react-ga';

function initializeGA(page = "/") {
    ReactGA.initialize('UA-166194470-1');
    pageview(page);
}
function eventGA(category, action) {
    ReactGA.event({
        category: 'central_do_cliente/'+category,
        action: action
    });
}

export { initializeGA, eventGA }