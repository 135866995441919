const thisInitialState = {
    plan: null,
    error: false,
    pending: false
}
//
export function plansReducer(state = thisInitialState, action) {
    switch (action.type) {
        //REFRESH
        case 'FETCH_PLAN_PENDING':
            return {
                ...state,
                pending: true,
                error: false,
                plan: null
            }
        case 'FETCH_PLAN_SUCCESS':
            return {
                ...state,
                pending: false,
                error: false,
                plan: action.plan
            }
        case 'FETCH_PLAN_ERROR':
            return {
                ...state,
                pending: false,
                error: true,
                plan: null
            }
        case 'FETCH_PLAN_PAY_PENDING':
            return {
                ...state,
                pending: true,
                error: false
            }
        case 'FETCH_PLAN_PAY_SUCCESS':
            return {
                ...state,
                pending: false,
                error: false
            }
        case 'FETCH_PLAN_PAY_ERROR':
            return {
                ...state,
                pending: false,
                error: true
            }
        default:
            return state;
    }
}